<template>
	<div>
		<HeadCard />
		<div class="content">
			<div class="tabsBox">
				<div class="title">
					<span>节点列表：{{ mode }}模式</span>
					<a-radio-group v-model="mode" size="large">
						<a-radio-button value="v2ray">V2ray</a-radio-button>
						<a-radio-button value="trojan">Trojan</a-radio-button>
			
					</a-radio-group>
				</div>
				<a-table
					:columns="columns"
					:data-source="data"
					:loading="loading"
					:total="pagination.total"
					:pagination="pagination"
					v-model="pagination.current"
					@change="handleTableChange"
					:rowKey="data.id"
					v-show="mode === 'trojan'"
				>
					<template slot="config" slot-scope="text, record">
						<a @click="info(record)">查看配置</a>
					</template>
					<a slot="name" slot-scope="text">{{ text }}</a>
					<span slot="status" slot-scope="text">
						<a-badge :status="text ? 'success' : 'error'" />
						{{ text ? '正常' : '异常' }}
					</span>
				</a-table>
				<a-table
					:columns="columns"
					:loading="loading"
					:pagination="pagination"
					:total="pagination.total"
					@change="handleTableChange"
					v-model="pagination.current"
					:data-source="data"
					v-show="mode === 'v2ray'"
				>
					<template slot="config" slot-scope="text, record">
						<a @click="info(record)">查看配置</a>
					</template>
					<a slot="name" slot-scope="text">{{ text }}</a>
					<span slot="status" slot-scope="text">
						<a-badge :status="text ? 'success' : 'error'" />
						{{ text ? '正常' : '异常' }}
					</span>
				</a-table>
			</div>
			<div class="noticeBox">
				<a-card title="公告" class="box1">
					<span>{{ this.CONSTNAME.Notice }}</span>
				</a-card>
				<a-card title="流量使用">
					<p>今日已用：{{ userInfo.today_used_traffic_gb }}G</p>
					<p>过去已用：{{ userInfo.used_traffic_gb }}G</p>
					<p>剩余流量：{{ userInfo.remain_traffic_gb }}G</p>
					<p>流量到期日：{{ userInfo.expired_at | dateformat('YYYY-MM-DD') }}</p>
				</a-card>
			</div>
		</div>
		<Footer style="margin-top: 150px" />
	</div>
</template>

<script>
import HeadCard from '../../components/HeadCard'
import Footer from '../../components/Footer'
import api from '../../api/api'
import moment from 'moment'
import { createNamespacedHelpers } from 'vuex'

const { mapActions, mapState } = createNamespacedHelpers('userInfo')

const columns = [
	{
		title: '节点名称',
		dataIndex: 'name',
		key: 'name'
	},
	{
		title: '倍率',
		dataIndex: 'ratio',
		key: 'ratio'
	},
	{
		title: '查看配置',
		dataIndex: '',
		key: 'config',
		ellipsis: true,
		scopedSlots: { customRender: 'config' }
	},
	{
		title: '状态',
		dataIndex: 'status',
		scopedSlots: { customRender: 'status' },
		key: 'status',
		ellipsis: true
	}
]
const data = []
export default {
	data() {
		return {
			mode: 'v2ray',
			data,
			columns,
			visible: false,
			time: '',
			loading: false,
			modelData: [],
			pagination: {
				pageSize: 10, // 默认每页显示数量
				showTotal: total => `共 ${total} 条`, // 显示总数
				total: 10, //总条数
				current: 1 //按钮页码
			}
		}
	},
	components: {
		HeadCard,
		Footer
	},
	mounted() {
		this.getListData(this.pagination.current, this.mode)
		this.timeData()
	},
	methods: {
		handleTableChange(pagination) {
			this.pagination = pagination
			this.getListData(pagination.current, this.mode)
		},
		info(e) {
			const arr = []
			let modearr = []
			let modedta = []
			const newdta = JSON.parse(JSON.stringify(e.config))
			const { name, host, port } = e
			for (let i in newdta) {
				modearr.push({ i: newdta[i] })
			}
			arr.push({ name, port, host, ...newdta })
			const newaobj = { ...arr[0] }

			for (let q in newaobj) {
				modedta.push(
					<p>
						{q}:{newaobj[q]}{' '}
					</p>
				)
			}
			this.$info({
				title: arr[0].name,
				content: () => <div>{modedta}</div>
			})
		},
		timeData() {
			const data = moment(this.userInfo.expired_at).format('YYYY-MM-DD')
			this.time = data
		},
		callback(key) {
			console.log(key)
		},
		showModal(e) {
			const { name, host, port } = e
			this.modelData.push({ name, host, port })
			this.visible = true
		},
		handleOk(e) {
			console.log(e)
			this.visible = false
		},
		async getListData(current, mode) {
			this.loading = true
			const data = await api.servers(mode, current)

			// var datastr = JSON.stringify(data, null, 2); 
			// console.log(`nodelist response [${datastr}]`)
					
			if (data.count>=0) {
				this.loading = false
			}
			this.pagination.total = data.count
			this.data = data.list
		},
		...mapActions(['getUserInfo'])
	},
	computed: {
		...mapState(['userInfo'])
	},
	watch: {
		mode: async function (val) {
			this.pagination.current = 1
			this.getListData(this.pagination.current, val)
		}
	}
}
</script>

<style scoped lang="scss">
.content {
	background: #f0f2f5;
	width: 65%;
	min-width: 1080px;
	margin: 0 auto;
	margin-top: 20px;
	display: flex;
	justify-content: space-between;
	.tabsBox {
		width: 76.5%;
		padding: 14px;
		background-color: #fff;
		border-radius: 5px;
		margin: 0;
		.title {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 16px;
		}
	}
	.noticeBox {
		width: 22%;
		.box1 {
			width: 100%;
			margin-bottom: 20px;
		}
	}
}
</style>
